import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import axios from 'axios';

const apiHost = process.env.REACT_APP_API_HOST;

function App() {
  const [isHomePage, setIsHomePage] = useState(true); // State to control homepage visibility
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [questionId, setQuestionId] = useState(1);  // Starting question ID
  const [surveyComplete, setSurveyComplete] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [resultOptions, setResultOptions] = useState(null);
  const [userSession, setUserSession] = useState(null);  // Store the user's UUID

  // Function to restart the survey
  const restartSurvey = () => {
    setCurrentQuestion(null); // Reset the current question state
    setQuestionId(1); // Reset to the first question
    setSurveyComplete(false); // Reset survey completion state
    setAnswers([]); // Clear the answers
    setResultOptions(null); // Clear the result options
    setUserSession(null); // Reset the user UUID
  };

  useEffect(() => {
    // Fetch the first question when the app loads or after restart
    if (!surveyComplete && !isHomePage) {
      const payload = userSession
        ? { question_id: questionId, answer: '', user_session: userSession } // Include UUID for subsequent requests
        : { question_id: questionId, answer: '' }; // For the first question, we don't need UUID initially

      axios
        .post(`${apiHost}/next-question/`, payload)
        .then((response) => {
          setCurrentQuestion(response.data);
          if (!userSession) {
            // Save the UUID after the first question
            setUserSession(response.data.user_session);
          }
        })
        .catch((error) => {
          console.error('Error fetching question:', error);
        });
    }
  }, [questionId, surveyComplete, userSession, isHomePage]);

  const handleAnswer = (answer) => {
    // Submit the answer and get the next question
    axios
      .post(`${apiHost}/next-question/`, { question_id: questionId, answer, user_session: userSession })
      .then((response) => {
        if (response.data.message) {
          setSurveyComplete(true);  // Mark survey as complete
          setAnswers(response.data.answers);  // Set the answers to display
          setResultOptions(response.data.result_options);
        } else {
          setCurrentQuestion(response.data);
          setQuestionId(response.data.next_question_id); // Move to the next question based on the response
        }
      })
      .catch((error) => {
        console.error('Error submitting answer:', error);
      });
  };

  // Render the homepage
  if (isHomePage) {
    return (
      <Container maxWidth="sm" style={{ textAlign: 'center' }}>
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Typography variant="h3" component="h1" gutterBottom>
            Welcome to Magic Tree AAC
          </Typography>
          <img
            src={`${process.env.PUBLIC_URL}/favicon.ico`}
            alt="Magic Tree Icon"
            style={{ width: '100px', height: '100px', margin: '20px auto', display: 'block' }}
          />
          <Typography variant="body1" paragraph>
            Discover your branch by answering a few questions!
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => setIsHomePage(false)}
            style={{ marginTop: '20px' }}
          >
            Start at the Roots
          </Button>
        </Paper>
      </Container>
    );
  }

  if (surveyComplete) {
    return (
      <Container maxWidth="sm" style={{ textAlign: 'left' }}>
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Typography variant="h4" component="h1" gutterBottom align="left">
            We have found your branch!
          </Typography>
          <div>
            <Typography variant="h6" gutterBottom align="left">Your Answers:</Typography>
            <ul>
              {answers.map((item, index) => (
                <li key={index}>
                  <strong>{item.question}</strong> - {item.answer}
                </li>
              ))}
            </ul>
            <Typography variant="h6" gutterBottom align="left">Magic Tree Results:</Typography>
            <ul>
              {resultOptions.map((item, index) => (
                <li key={index}>
                  <strong>{item.name}</strong>
                  <ul>
                    {item.reason.map((sub_item, sub_index) => (
                      <li key={sub_index}>
                        <span dangerouslySetInnerHTML={{ __html: sub_item }} />
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
          <Button variant="contained" color="primary" onClick={restartSurvey} style={{ marginTop: '20px' }}>
            Start Over
          </Button>
        </Paper>
      </Container>
    );
  }  

  if (!currentQuestion) {
    return <div>Loading...</div>;
  }

  return (
    <Container maxWidth="sm" style={{ textAlign: 'center' }}>
      <Paper elevation={3} style={{ padding: '20px' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {currentQuestion.question}
        </Typography>
        <Stack direction="column" spacing={2} alignItems="center">
          {Object.keys(currentQuestion.answers).map((answer, idx) => (
            <Box key={idx} style={{ width: '100%' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleAnswer(answer)}
                size="large"
                fullWidth
              >
                {answer}
              </Button>
            </Box>
          ))}
        </Stack>
      </Paper>
    </Container>
  );
}

export default App;
